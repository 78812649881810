import { IProduct } from '@/components/RootComponents/Category/modules/ProductCard/types';
import { IProductBadgesMap } from '@/components/ProductBadges/types';
import { mapBadgesWithId } from '@/components/ProductBadges/util/mapBadgesWithId';

export const applyProductBadgesToProductList = (
    items: Array<IProduct>,
    badgeMap: IProductBadgesMap,
): Array<IProduct> => {
    if (!items) return items;

    return items.map((product) => {
        return {
            ...product,
            badgesList: mapBadgesWithId({ allBadgesMap: badgeMap, amLabelInfo: product.amLabelInfo }),
        };
    });
};
