'use client';

import { createContext, ReactNode, useContext } from 'react';
import { IProductBadgesMap } from '@/components/ProductBadges/types';

export const ProductBadgeCategoryContext = createContext<IProductBadgesMap>(new Map() as IProductBadgesMap);

export const ProductBadgeCategoryProvider = ({
    badgesMap,
    children,
}: {
    badgesMap: IProductBadgesMap;
    children: ReactNode;
}) => {
    return <ProductBadgeCategoryContext.Provider value={badgesMap}>{children}</ProductBadgeCategoryContext.Provider>;
};

export const useProductBadgeCategoryContext = () => {
    return useContext(ProductBadgeCategoryContext);
};
