import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { usePathname, useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import {
    convertObjectToSearchParams,
    getFilters,
    getFiltersFromSearch,
} from '@/components/RootComponents/Category/modules/FilterBlock/utils/helpers';
import { getCategoryProductsClientQuery } from '@/components/RootComponents/Category/api/category.gql';
import { useProductListingPage } from '@/integrations/monetate';
import { useFredHopper } from '@/components/RootComponents/Category/hooks/useFredHopper';
import type { IUseProductsGridProps } from '../types';
import type { IProduct } from '@/components/RootComponents/Category/modules/ProductCard/types';
import { modifyHistoryState } from '@/components/RootComponents/Category/modules/ProductsGrid/utils/modifyHistoryState';
import { PrefetchKind } from 'next/dist/client/components/router-reducer/router-reducer-types';
import { applyProductBadgesToProductList } from '@/components/ProductBadges/util/applyProductBadgesToProductList';
import { useProductBadgeCategoryContext } from '@/components/ProductBadges/context/productBadgesCategoryContext';

export const useProductsGrid = ({
    breadcrumbs,
    categoryId,
    currentPage,
    fhrId,
    filterType,
    initialItems,
    isCategoryPage = false,
    pageSize,
    search,
    setProductsData,
    totalCount,
    totalPages,
}: IUseProductsGridProps) => {
    const t = useTranslations('global');

    const router = useRouter();
    const pathname = usePathname();
    const [inViewport, setInViewport] = useState(false);
    const [cachedFirstLoadPage, setCachedFirstLoadPage] = useState(currentPage);
    const [previousSearchParams, setPreviousSearchParams] = useState(search);
    const [products, setProducts] = useState(initialItems);
    const [nextFetchProducts, setNextFetchProducts] = useState(initialItems);
    const [page, setPage] = useState(currentPage);
    const [countItems, setCountItems] = useState(totalCount);
    const badgesMap = useProductBadgeCategoryContext();

    const [fetchNextProducts, { loading }] = useLazyQuery(getCategoryProductsClientQuery, {
        errorPolicy: 'ignore',
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const isUniqueItems = (items: IProduct[], previousItems: IProduct[]) =>
        items.some(({ id }) => !previousItems.some((previousItem) => previousItem.id === id));

    const fetchMoreData = useCallback(
        async (param: string | number, isPreviousItems: boolean = false) => {
            const { filters, sort } = getFilters(categoryId, filterType, search);

            const { data } = await fetchNextProducts({
                variables: {
                    currentPage: Number(param),
                    filters,
                    inputText: search?.q || '',
                    pageSize,
                    sort,
                },
            });

            const dataProductItems = data?.products?.items || [];

            if (!isUniqueItems(dataProductItems, products)) {
                return;
            }

            setNextFetchProducts(dataProductItems);

            if (page < currentPage || isPreviousItems) {
                setProducts((prev) =>
                    applyProductBadgesToProductList([...(data?.products?.items || []), ...prev], badgesMap),
                );
            } else {
                setProducts((prev) => applyProductBadgesToProductList([...prev, ...dataProductItems], badgesMap));
            }
        },
        [initialItems, products],
    );

    useEffect(() => {
        if (JSON.stringify(previousSearchParams) === JSON.stringify(search) && totalCount === countItems) {
            return;
        }

        setProducts(applyProductBadgesToProductList(initialItems, badgesMap));
        setPreviousSearchParams(search);
        setCountItems(totalCount);
        setPage(currentPage);
    }, [initialItems, search]);

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    useEffect(() => {
        if (!(page > totalPages) && inViewport && products?.length < totalCount) {
            fetchMoreData(page);
        }
    }, [inViewport, page]);

    const changePagination = useCallback(
        (param: number) => {
            const nextSearch = convertObjectToSearchParams(search);

            nextSearch?.set('p', param.toString());
            setPreviousSearchParams({ ...search, p: param });
            setPage(param);

            // We need to prefetch and cache NextJsTreePayload for smoother backward navigation.
            router.prefetch(`${pathname}?${nextSearch?.toString()}`, { kind: PrefetchKind.FULL });
            modifyHistoryState(pathname, nextSearch, isCategoryPage);
        },
        [search],
    );

    const handlePaginationBack = useCallback(() => {
        if (page < 2) return;

        fetchMoreData(page - 1, true);
        changePagination(page - 1);
        setCachedFirstLoadPage(page - 1);
    }, [setPage, page]);

    const handlePaginationForward = useCallback(() => {
        if (page < totalPages) {
            fetchMoreData(page + 1);
            changePagination(page + 1);
        }
    }, [page, setPage, totalPages]);

    const showBackButton =
        totalPages > 1 &&
        ((currentPage > page && page !== 1) || (page * pageSize > products.length && totalCount > products.length));
    const showNextButton = totalPages > 1 && page !== totalPages && totalCount > products.length;

    const { commonData: fredHopper, searchTerms } = useFredHopper(fhrId || '');
    const breadcrumbsItems = breadcrumbs ? [t('home'), ...breadcrumbs.map(({ text }) => text)] : [];

    useProductListingPage({ breadcrumbs: breadcrumbsItems, pageType: isCategoryPage ? 'index' : 'search', products });

    useEffect(() => {
        setProductsData({
            fredHopper: {
                ...fredHopper,
                searchTerms,
            },
            items: products,
        });
    }, [products?.length]);

    return {
        cachedFirstLoadPage,
        changePagination,
        handlePaginationBack,
        handlePaginationForward,
        hasFilters: !!getFiltersFromSearch(search || '')?.size,
        loading,
        nextFetchProducts,
        page,
        pageControl: {
            currentPage: page,
            setPage,
            totalPages,
        },
        products,
        setInViewport,
        showBackButton,
        showNextButton,
    };
};
