import { IProductBadgesFragment, IProductBadgesMap } from '@/components/ProductBadges/types';
import {
    BadgePositionEnum,
    IProductBadge,
} from '@vaimo-int/aem-pwa-common-components/dist/components/ProductBadge/types';

export const mapBadgesWithId = ({
    allBadgesMap,
    amLabelInfo,
}: {
    allBadgesMap: IProductBadgesMap;
} & IProductBadgesFragment): Array<IProductBadge> => {
    const filteredBadgesMap = new Map<BadgePositionEnum, IProductBadge>();

    if (!amLabelInfo) return [];

    for (const label of amLabelInfo) {
        const badge = allBadgesMap.get(label.label_id);
        if (!badge) continue;

        if (filteredBadgesMap.has(badge.position as BadgePositionEnum)) continue;

        filteredBadgesMap.set(badge.position as BadgePositionEnum, badge);

        if (filteredBadgesMap.size === 2) {
            break;
        }
    }

    return Array.from(filteredBadgesMap.values());
};
